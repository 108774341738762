<style lang="scss" scoped>
::v-deep .van-cell {
	border: 0;
	&.van-cell::after {
		border: 0;
	}
}
.content_text {
	::v-deep .van-cell {
		padding: 0.2rem 0;
		border-bottom: 0.02rem solid #eeeff0;

		&:last-child {
			border-bottom: 0;
		}
	}
}
::v-deep .cell_title {
	color: $font_color_sec;
	font-size: 0.24rem;
}
::v-deep .cell_value {
	color: $font_color_val;
	font-size: 0.24rem;
}
::v-deep .van-field__body input {
	color: $font_color_val;
}
::v-deep .no_link .van-icon-arrow {
	color: #fff;
}

::v-deep .van-collapse-item__title {
	padding-left: 0;
}
::v-deep .van-collapse-item__content {
	padding-left: 0;
	padding-right: 0;
	padding-top: 0;
}
.card_box {
	box-shadow: 0rem 0.04rem 0.16rem 0rem rgba(0, 0, 0, 0.2);
	border-radius: 0.2rem 0.2rem 0.2rem 0.2rem;
	padding: 0 0.2rem;
	.new_main_title {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-size: 0.28rem;
		font-weight: 600;
	}
	.line_title {
		width: 0.08rem;
		height: 0.32rem;
		background: $b_color_main;
		margin-right: 0.2rem;
	}
}
</style>

<template>
	<div class="insure_user card_box">
		<van-collapse v-model="activeNames">
			<van-collapse-item name="1" :border="false">
				<template #title>
					<div class="new_main_title">
						<div class="line_title"></div>
						<span>{{ $t('informationOfPolicyholder') }}</span>
					</div>
				</template>
				<div class="content_text">
					<van-form v-if="!qrCode.fixedInsureInfo" colon:b ref="form" input-align="right" error-message-align="right" :scroll-to-error="true">
						<van-cell-group :border="false" v-if="userTypeDict">
							<van-field :label="$t('typeOfPolicyholder')" :value="userInfo.userType" :disabled="qrCode.fixedInsureInfo" label-class="cell_title" value-class="cell_value" :border="false" is-link readonly clickable name="picker" placeholder="请选择投保人类型" @click="insureTypeSelectClick('userType', 'userTypePop')" />
							<van-field v-if="userTypeDict.name == 1" :label="$t('name')" :disabled="qrCode.fixedInsureInfo" v-model="userInfo.userName" :formatter="nameFormatter" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="请填写投保人姓名" :rules="rules.userName" />
							<van-field v-if="userTypeDict.name == 2" :label="$t('companyName')" :disabled="qrCode.fixedInsureInfo" v-model="userInfo.userName" :formatter="nameFormatter" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="请填写企业名称" :rules="rules.userName" />
							<van-field v-if="userTypeDict.name == 1" :label="$t('idType')" :disabled="qrCode.fixedInsureInfo" :value="userInfo.cardType" label-class="cell_title" value-class="cell_value" :border="false" is-link readonly clickable name="picker" placeholder="请选择证件类型" @click="insureTypeSelectClick('cardType', 'cardTypePop')" />
							<van-field v-if="userTypeDict.name == 2" :label="$t('idType')" :disabled="true" :value="userInfo.groupCardType" label-class="cell_title" value-class="cell_value" :border="false" is-link readonly clickable name="picker" placeholder="请选择证件类型" @click="insureTypeSelectClick('groupCardType', 'groupCardTypePop')" />
							<van-field label="证件号码" v-model="userInfo.cardNum" :disabled="qrCode.fixedInsureInfo" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" @input="cardNumChange" placeholder="请填写投保人证件号码" :rules="rules.cardNum" />
							<van-field v-if="userTypeDict.name == 1" :label="$t('birthday')" :disabled="qrCode.fixedInsureInfo" :value="userInfo.birthday" label-class="cell_title" value-class="cell_value" :border="false" is-link readonly clickable name="picker" :rules="rules.birthday" placeholder="请选择出生日期" @click="insureTypeSelectClick('birthday', 'birthdayPop')" />
							<van-field v-if="userTypeDict.name == 1" :label="$t('sex')" :disabled="qrCode.fixedInsureInfo" :value="userInfo.sex" label-class="cell_title" value-class="cell_value" :border="false" is-link readonly clickable name="picker" :rules="rules.sex" placeholder="请选择性别" @click="insureTypeSelectClick('sex', 'sexPop')" />
							<van-field :label="$t('mobile')" v-model="userInfo.phone" :disabled="qrCode.fixedInsureInfo" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="请填写投保人手机号码" :rules="rules.phone" />
							<van-field :label="$t('email')" v-model="userInfo.email" :disabled="qrCode.fixedInsureInfo" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="请填写投保人电子邮箱" :rules="rules.email" />
							<van-field :label="$t('invoiceTitle')" v-model="userInfo.userName" disabled label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" placeholder="请填写发票抬头" />
							<div class="line"></div>
							<van-field :label="$t('purchaseQuantity')" v-model="userInfo.buyNum" disabled label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" />
							<div class="line"></div>
						</van-cell-group>
					</van-form>

					<template v-else>
						<van-cell-group :border="false" v-if="userTypeDict">
							<van-cell title-class="cell_title" value-class="cell_value" :border="false" :title="$t('typeOfPolicyholder')" :value="sympleInfo.userType"></van-cell>
							<van-cell title-class="cell_title" value-class="cell_value" :border="false" :title="$t('name')" v-if="userTypeDict.name == 1" :value="sympleInfo.userName"></van-cell>
							<van-cell title-class="cell_title" value-class="cell_value" :border="false" :title="$t('companyName')" v-if="userTypeDict.name == 2" :value="sympleInfo.userName"></van-cell>
							<van-cell title-class="cell_title" value-class="cell_value" :border="false" :title="$t('idType')" :value="sympleInfo.cardType"></van-cell>
							<van-cell title-class="cell_title" value-class="cell_value" :border="false" :title="$t('cardNo')" :value="sympleInfo.cardNum"></van-cell>
							<van-cell title-class="cell_title" value-class="cell_value" :border="false" :title="$t('birthday')" v-if="userTypeDict.name == 1" :value="sympleInfo.birthday"></van-cell>
							<van-cell title-class="cell_title" value-class="cell_value" :border="false" :title="$t('sex')" v-if="userTypeDict.name == 1" :value="sympleInfo.sex"></van-cell>
							<van-cell title-class="cell_title" value-class="cell_value" :border="false" :title="$t('mobile')" :value="sympleInfo.phone"></van-cell>
							<van-cell title-class="cell_title" value-class="cell_value" :border="false" :title="$t('email')" :value="sympleInfo.email"></van-cell>
							<van-cell title-class="cell_title" value-class="cell_value" :border="false" :title="$t('invoiceTitle')" :value="sympleInfo.userName"></van-cell>
							<van-cell title-class="cell_title" value-class="cell_value" :border="false" :title="$t('purchaseQuantity')" value="1"></van-cell>
						</van-cell-group>
					</template>
				</div>
			</van-collapse-item>
		</van-collapse>

		<!-- 投保人类型 -->
		<van-popup v-model="userTypePop" position="bottom" :style="{ height: '30vh' }">
			<van-picker title="投保人类型" show-toolbar value-key="value" :visible-item-count="3" :columns="userTypeList" @confirm="userTypeCheck" @cancel="userTypePop = false" />
		</van-popup>

		<!-- 投保人证件类型-个人 -->
		<van-popup v-model="cardTypePop" position="bottom" :style="{ height: '30vh' }">
			<van-picker title="证件类型" show-toolbar value-key="label" :visible-item-count="3" :columns="cardTypeList" @confirm="cardTypeCheck" @cancel="cardTypePop = false" />
		</van-popup>

		<!-- 出生日期 -->
		<van-popup v-model="birthdayPop" position="bottom" :style="{ height: '30vh' }">
			<van-datetime-picker type="date" v-model="currentDate" title="选择年月日" @cancel="birthdayPop = false" @confirm="birthdayCheck" visible-item-count="3" :min-date="birthdayMinDate" />
		</van-popup>

		<!-- 性别 -->
		<van-popup v-model="sexPop" position="bottom" :style="{ height: '30vh' }">
			<van-picker title="性别" show-toolbar value-key="label" :visible-item-count="3" :columns="sexList" @confirm="sexCheck" @cancel="sexPop = false" />
		</van-popup>
	</div>
</template>

<script>
import { Collapse, CollapseItem, Cell, CellGroup, Popup, Picker, Calendar, Field, DatetimePicker, Checkbox, ActionSheet, Button, CountDown, Form, Toast, Dialog } from 'vant';
import { insureUserType, cardType, groupCardType, sexs, cardTypeMap, cardTypeMap2 } from '@/config/fixedParams';
import regular from '@/assets/js/regular';
import Mtils from 'mtils';

export default {
	name: 'insureUser', // 投保人信息
	components: {
		[Collapse.name]: Collapse,
		[CollapseItem.name]: CollapseItem,
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[Popup.name]: Popup,
		[Picker.name]: Picker,
		[Calendar.name]: Calendar,
		[Field.name]: Field,
		[DatetimePicker.name]: DatetimePicker,
		[Checkbox.name]: Checkbox,
		[ActionSheet.name]: ActionSheet,
		[Button.name]: Button,
		[CountDown.name]: CountDown,
		[Form.name]: Form,
		[Toast.name]: Toast,
		[Dialog.Component.name]: Dialog.Component,
	},
	props: {
		qrCode: {
			type: Object,
			required: false,
			default: function () {
				return {
					fixedInsureInfo: false,
				};
			},
		},
	},
	data() {
		return {
			activeNames: ['1'],

			sympleInfo: {
				userType: '',
				userName: '',
				cardType: '',
				cardNum: '',
				birthday: '',
				sex: '',
				phone: '',
				email: '',
			},

			userInfo: {
				userType: '个人',
				userName: '',
				cardType: '',
				groupCardType: '统一社会信用代码',
				cardNum: '',
				birthday: '',
				sex: '',
				phone: '',
				email: '',
				buyNum: '1',
			},
			rules: {
				time: [{ required: true }],
				start: [{ required: true }],
				userName: [{ required: true }, { pattern: /^[a-zA-Z()（）·]+$|^[\u4e00-\u9fa5()（）·]+$/, message: '只能输入中文或英文以及()·' }, { pattern: /[a-zA-Z]{4,}|[\u4e00-\u9fa5]{2,}/, message: '至少2个中文或4个英文字母' }, { validator: this.nameCheck, message: '·不能出现在首尾' }],
				cardNum: [{ required: true }, { validator: this.cardNumCheck, message: '证件号码有误' }],
				birthday: [{ required: true }],
				sex: [{ required: true }],
				phone: [{ required: true }, { pattern: regular.phone, message: '请填写正确的手机号码' }],
				email: [{ required: false }, { validator: this.emailCheck, message: '请输入正确的邮箱地址' }],
			},

			userTypeList: insureUserType,
			userTypeDict: insureUserType[0],
			cardTypeList: cardType,
			birthdayMinDate: new Date('1900/01/01'),
			birthday: '1990/01/01',
			sexPop: false,
			sexList: sexs,
			userTypePop: false,
			cardTypePop: false,
			birthdayPop: false,
			currentDate: new Date(),

			hasRender: false,
		};
	},
	watch: {
		qrCode: {
			handler: function (v) {
				if (v.fixedInsureInfo) {
					this.userInfo.userType = v.insureType;
					this.sympleInfo.userType = v.insureType;
					this.userInfo.userName = v.insureName;
					this.sympleInfo.userName = this.sympleFormatter(v.insureName);
					if (v.cardType) {
						if (v.insureType === '个人') {
							this.userInfo.cardType = cardTypeMap[v.cardType];
							this.userInfo.birthday = v.birthday.replace(/-/g, '/');
							this.userInfo.sex = v.sex;
							this.userTypeDict = insureUserType[0];

							this.sympleInfo.cardType = this.userInfo.cardType;
							this.sympleInfo.cardNum = this.sympleFormatter(v.cardNum, 'idCard');
							this.sympleInfo.birthday = this.userInfo.birthday;
							this.sympleInfo.sex = v.sex;
						}

						if (v.insureType === '企业') {
							this.userInfo.groupCardType = cardTypeMap[v.cardType];
							this.userTypeDict = insureUserType[1];
							this.sympleInfo.cardType = this.userInfo.groupCardType;
							this.sympleInfo.cardNum = this.sympleFormatter(v.cardNum);
						}
					}
					this.userInfo.cardNum = v.cardNum;
					this.userInfo.phone = v.mobile;
					this.sympleInfo.phone = this.sympleFormatter(v.mobile, 'mobile');
					this.userInfo.email = v.email;
					this.sympleInfo.email = this.sympleFormatter(v.email, 'email');

					// 其实没必要在这里处理，因为qrCode不请求到，该组件就不会渲染（因为父组件中有排序，用了vif）。请求到，就不会发生变化
					if (this.hasRender) {
						// 固定信息就默认折叠起来
						this.activeNames = ['0'];
					}
				}

				if (v.fixedInsureInfo === false) {
					this.activeNames = ['1'];
				}
			},
			immediate: true,
			deep: true,
		},
	},
	mounted() {
		// 因为van-collapse最初不打开的不渲染，不渲染，就无法执行form校验方法，所以这里做一下处理
		this.hasRender = true;
		if (this.qrCode.fixedInsureInfo) {
			this.activeNames = ['0'];
		}
	},
	methods: {
		getResult() {
			return new Promise(resolve => {
				if (this.qrCode.fixedInsureInfo) {
					this.userInfo.finalCardType;
					let key = {
						1: 'cardType',
						2: 'groupCardType',
					};
					let result = {
						finalCardType: cardTypeMap2[this.userInfo[key[this.userTypeDict.name]]],
						insType: this.userTypeDict.name,
					};
					Object.assign(result, this.userInfo);
					resolve(result);
				} else {
					this.$refs.form
						.validate()
						.then(() => {
							this.userInfo.finalCardType;
							let key = {
								1: 'cardType',
								2: 'groupCardType',
							};
							let result = {
								finalCardType: cardTypeMap2[this.userInfo[key[this.userTypeDict.name]]],
								insType: this.userTypeDict.name,
							};
							Object.assign(result, this.userInfo);
							resolve(result);
						})
						.catch(() => {
							Toast.fail('请检查投保人信息');
						});
				}
			});
		},

		// 脱敏 type: mobile,idCard,email
		sympleFormatter(str = '', type = '') {
			if (type === 'mobile') {
				return str.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
			} else if (type === 'idCard') {
				return str.slice(0, 14).padEnd(18, '*');
			} else if (type === 'email') {
				if (str) {
					return this.sympleFormatter(str.split('@')[0]) + '@' + str.split('@')[1];
				} else {
					return '';
				}
			} else {
				if (str.length <= 3) {
					return str.charAt(0).padEnd(str.length, '*');
				} else if (str.length > 3 && str.length <= 6) {
					return str.charAt(0).padEnd(str.length - 1, '*') + str.charAt(str.length - 1);
				} else {
					return str.slice(0, 2).padEnd(str.length - 2, '*') + str.slice(str.length - 2);
				}
			}
		},

		// 投保人姓名格式化
		nameFormatter(v) {
			return v.toLocaleUpperCase();
		},

		// 投保人信息-下拉框被点击
		insureTypeSelectClick(modelKey, popKey) {
			if (this.qrCode.fixedInsureInfo) {
				return;
			}
			this[popKey] = true;
		},

		birthdayCheck(val) {
			this.userInfo.birthday = this.$base.dateFormater(val);
			this.birthdayPop = false;
		},

		sexCheck(val) {
			this.userInfo.sex = val.label;
			this.sexPop = false;
		},

		// 证件号码输入
		cardNumChange(val) {
			if (val && this.userInfo.cardType === '身份证') {
				// 处理大小写问题
				this.userInfo.cardNum = String(val).toLocaleUpperCase();
			}
			if (this.userInfo.cardType === '身份证' && Mtils.validation.isIdCard(this.userInfo.cardNum)) {
				// 计算
				let info = this.$base.getInfoFromIdNumber(val);
				this.userInfo.birthday = info.birthday;
				this.userInfo.sex = info.sex;
				this.currentDate = new Date(info.birthday);
			}
		},

		userTypeCheck(val) {
			if (this.userInfo.userType !== val.value) {
				// 清空
				this.userInfo.userName = '';
				this.userInfo.cardNum = '';
				this.userInfo.cardType = '';
				this.userInfo.birthday = '';
				this.userInfo.sex = '';
			}

			this.userTypeDict = val;
			this.userInfo.userType = val.value;
			this.userTypePop = false;
		},

		cardTypeCheck(val) {
			this.userInfo.cardType = val.label;
			this.cardTypePop = false;
		},

		// 投保人名称检测
		nameCheck(val) {
			if (val[0] === '·' || val[val.length - 1] === '·') {
				return false;
			}
			return true;
		},

		// 证件号码校验方法
		cardNumCheck(val) {
			if (this.userInfo.userType === '个人') {
				if (this.userInfo.cardType === '身份证') {
					return Mtils.validation.isIdCard(val);
				}
				if (this.userInfo.cardType === '护照') {
					return regular.passport.test(val) && !regular.repeatReg7.test(val) && !regular.sequentialReg7.test(val);
				}
				if (this.userInfo.cardType === '军官证') {
					return regular.army.test(val);
				}
				if (this.userInfo.cardType === '港澳回乡证') {
					return regular.hkCard.test(val);
				}
				if (this.userInfo.cardType === '台胞证') {
					return regular.twCard.test(val);
				}
			} else {
				return Mtils.validation.isCreditCode(val);
			}
			return true;
		},

		// 邮箱检测
		emailCheck(val) {
			if (val) {
				return regular.email.test(val);
			}
			return true;
		},
	},
};
</script>
