<style lang="scss" scoped>
.nav_menu_right {
	float: right;
	font-size: 0.24rem;
	margin-left: -2rem;
	background: rgba(0, 0, 0, 0.15);
	width: 1.6rem;
	border-radius: 1.2rem;
	line-height: 0.6rem;
	margin-top: 0.14rem;
}
.nav_menu_left {
	float: left;
	font-size: 0.24rem;
	margin-right: -2rem;
	background: rgba(0, 0, 0, 0.15);
	width: 1.6rem;
	border-radius: 1.2rem;
	line-height: 0.6rem;
	margin-top: 0.14rem;
}
.weChat_warning {
	font-size: 0.4rem;
	text-align: center;
}
.pv_4 {
	padding: 0 0.4rem;
}
.mt_4 {
	margin-top: 0.4rem;
}
.assured_box {
	box-shadow: 0rem 0.04rem 0.16rem 0rem rgba(0, 0, 0, 0.2);
	border-radius: 0.2rem 0.2rem 0.2rem 0.2rem;
	padding: 0.2rem;
	margin: 0.4rem;
}
</style>

<template>
	<div class="fixed_code_insure">
		<div class="weChat_warning" v-if="weChatWarningShow">
			<h4>请在微信或支付宝中打开</h4>
		</div>
		<div v-else>
			<top-nav ref="nav" :showBack="false">
				<div class="flex_center">
					<span v-text="$t('productDetails')">产品详情</span>
					<span class="nav_menu_right" @click="$router.push('orderSearch')" v-text="$t('orderSearch')">订单查询</span>
					<span class="nav_menu_left" @click="changeLanguage" v-text="$t('language')"></span>
				</div>
			</top-nav>

			<template v-if="qrCodeInfo.templateSequence">
				<template v-for="n in qrCodeInfo.templateSequence">
					<banner :key="n" v-if="n === '1'" name="banner" :url="banner"></banner>
					<div :key="n" v-if="n === '2'" class="pv_4 mt_4 assured_box">
						<assured :proId="proSimpleInfo.id" name="被保险人" ref="assured"></assured>
						<!-- <assureForm v-else-if="assuredDataV2 && !isLiability" :group="assuredDataV2" ref="assuredForm" :needBtn="false" backPath="indexOneselfPay" />
						<insuranceForm v-else-if="assuredDataV2 && isLiability" :group="assuredDataV2" ref="assuredForm" /> -->
						<clause-info name="条款信息(可回溯信息)" ref="clauseInfo" :proInfo="proSimpleInfo" :information="information" :planId="planId" :proId="qrCodeInfo.proId"></clause-info>
					</div>
					<!-- <div :key="n" v-if="n === '3'" class="pv_4 mt_4">
						<clause-info name="条款信息(可回溯信息)" ref="clauseInfo" :proInfo="proSimpleInfo" :information="information" :planId="planId"></clause-info>
					</div> -->
					<div :key="n" v-if="n === '4'" class="pv_4 mt_4">
						<insure-info name="投保信息" ref="insureInfo" @insureTimeChange="insureTimeChange" :fixedEnableChange="qrCodeInfo.updateEnabledDate" :fiexdEnableDate="qrCodeInfo.fixedEnabledDate" :product="proInfo" :planId="planId" :proId="qrCodeInfo.proId" :priceId="qrCodeInfo.priceId" :enabledDateStart="qrCodeInfo.enabledDateStart"></insure-info>
						<!-- <insuranceForm v-else-if="productDataV2" :group="productDataV2" ref="productForm" :fixedEnableChange="qrCodeInfo.updateEnabledDate" :fiexdEnableDate="qrCodeInfo.fixedEnabledDate" /> -->
					</div>
					<div :key="n" v-if="n === '5'" class="pv_4 mt_4">
						<insure-user name="投保人信息" ref="insureUser" :qrCode="qrCodeInfo"></insure-user>
						<!-- <insuranceForm v-else-if="insureDataV2" :group="insureDataV2" ref="insureForm" :fixedDisabled="qrCodeInfo.fixedInsureInfo" /> -->
					</div>
					<div :key="n" v-if="n === '6'" class="pv_4 mt_4">
						<other-info name="其它信息"></other-info>
					</div>

					<div :key="n" v-if="n === '7'" class="pv_4 mt_4">
						<plan name="产品名称与计划" @planChange="planChange" :price="price" :protectViewTime="protectViewTime" :plans="planList" :proInfo="proSimpleInfo" :insureInfo="insureInfo" :information="information" :planId="planId"></plan>
					</div>
					<div :key="n" v-if="n === '8'" class="pv_4 mt_4">
						<pro-narrate name="产品解读" :information="information" :questionList="questionList"></pro-narrate>
					</div>
				</template>
			</template>
			<price-buy-btn :price="totalPrice" @buy="buy">{{ $t('buy') }}</price-buy-btn>
		</div>

		<!-- 确定进入投保流程 -->
		<liPenterSure v-model="enterWarning" :name="insurerFullName" :fileUrl="notificationLink" @confirm="agreeEnter" :proId="[this.proInfo.proId]"></liPenterSure>
	</div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import banner from './components/banner.vue';
import plan from './components/plan.vue';
import assured from './components/assured.vue';
import insureInfo from './components/insureInfo.vue';
import insureUser from './components/insureUser.vue';
import otherInfo from './components/otherInfo.vue';
import clauseInfo from './components/clauseInfo.vue';
import proNarrate from './components/proNarrate.vue';
import { loginNoPwd, productDetail, getPrice, getInsuerInfo, submitBill } from '@/request/api';
import { http_getqrCodeInfo } from '@/request/fixedCodeInsure';
import { http_getServerTime } from '@/request/common';
import signatureMixin from '@/components/signature.js';
import { http_getProductConfig, http_insuredOrder } from '../../request/insureV2';
// import insuranceForm from '../insureV2/components/insuranceForm.vue';
// import assureForm from '../insureV2/components/assureForm.vue';
import liPenterSure from '@/components/liPenterSure.vue';

export default {
	name: 'indexOneselfPay', // 自己支付模式（谁投保谁支付）
	components: {
		[Toast.name]: Toast,
		banner,
		plan,
		assured,
		insureInfo,
		insureUser,
		otherInfo,
		clauseInfo,
		proNarrate,
		[Dialog.Component.name]: Dialog.Component,
		liPenterSure,
		// insuranceForm,
		// assureForm,
	},
	mixins: [signatureMixin],
	provide() {
		return {
			comboId: () => this.planId,
			proId: () => this.proInfo.proId,
			comboList: () => this.comboList,
			productInfo: () => this.productInfo,
			insProductInformation2Vo: () => this.insProductInformation2Vo,
		};
	},
	data() {
		return {
			weChatWarningShow: false,
			enterWarning: true,
			userDesc: '',
			qrCodeId: '',

			// 立牌信息
			qrCodeInfo: {},

			proBanner: '', // 产品默认banner
			proInfo: {}, // 产品信息
			proSimpleInfo: {}, // 产品信息，两个接口请求来的产品信息内容不同，切用到的参数不是交集的内容，所以只能设置两个
			proPlanList: [], // 产品默认计划
			insureInfo: {}, // 投保信息
			information: {}, //
			questionList: [], // 问答
			timeList: [],
			insurerFullName: '', //保险公司名称
			notificationLink: '', //告知书文档

			planId: NaN,

			price: 0, // 单价
			protectViewTime: '', // 产品详情中产品名称下面显示的保障期限

			// 下单多久之后为起保时间
			immediateExtendTime: 0,

			protectInfo: {}, // 保障期限信息

			isCh: true, // 多语言是否为中文

			lastRouteName: '', // 离开该页面时去的路由Name
			// isV1: true, //旧平台
			productDataV2: null, //v2投保信息
			insureDataV2: null, //v2投保人信息
			assuredDataV2: null, //v2被保人信息
			productInfo: {}, //v2商品信息
			comboId: '',
			comboList: [],
			fieldParent: {},
			userId: '', //用户id
			// isLiability: false, //是否是旅险
		};
	},
	computed: {
		banner() {
			return this.qrCodeInfo.banner ? this.qrCodeInfo.banner : this.proBanner;
		},

		planList() {
			if (Object.hasOwnProperty.call(this.qrCodeInfo, 'goods')) {
				let showPlans = this.qrCodeInfo.goods.split(',').map(id => Number(id));
				return this.proPlanList.filter(item => showPlans.includes(item.comboId));
			}
			return [];
		},

		totalPrice() {
			let num = this.$refs.assured ? this.$refs.assured[0].userList.length : 0;
			return this.$base.floatPrice(this.price * num);
		},
		// v2产品信息价格
		priceV2() {
			return this.$store.state.insuranceV2.price;
		},
		//v2总价格
		totalPriceV2() {
			return this.$store.state.insuranceV2.allPrice;
		},
	},
	watch: {
		$route: {
			handler: function (route) {
				if (route.name !== 'indexOneselfPay') {
					this.lastRouteName = route.name;
				}
				if (route.query.hasOwnProperty('userInfo') && route.name === 'indexOneselfPay' && this.lastRouteName === 'addPassportUser') {
					this.$refs.assured[0].addPassportUser(JSON.parse(route.query.userInfo));
				}
			},
		},
	},
	created() {
		this.init();
	},
	methods: {
		init() {
			if (!this.$base.isDev() || this.$base.getEnv()) {
				this.urlParams();

				let query = this.$route.query;
				if (Object.hasOwnProperty.call(query, 'openWelcome')) {
					// 从签名页来的，已经登录过了，这路不用再次登录
					this.getQrCodeInfo().then(() => {
						this.getGoods();
						this.getPlain();
					});
				} else {
					this.login().then(() => {
						this.getQrCodeInfo().then(() => {
							this.getGoods();
							this.getPlain();
						});
					});
				}
			} else {
				this.weChatWarningShow = true;
			}
		},

		// 切换语言
		changeLanguage() {
			// 暂时只提供中英文
			this.isCh = !this.isCh;
			this.$i18n.locale = this.isCh ? 'zhCN' : 'en';
		},

		// 获取地址中的参数
		urlParams() {
			if (window.location.search) {
				this.userDesc = this.$route.query.user;
				this.qrCodeId = this.$route.query.qrcode;
			} else {
				window.location.href = localStorage.getItem('homeUrl');
			}

			// let paramsList = window.location.search.split(/=|&/);
			// this.userDesc = paramsList[1];
			// this.qrCodeId = paramsList[3];
		},

		// 登录
		login() {
			return new Promise(resolve => {
				loginNoPwd(this.userDesc).then(() => {
					resolve();
				});
			});
		},

		// 获取立牌信息
		getQrCodeInfo() {
			return new Promise(resolve => {
				http_getqrCodeInfo(this.qrCodeId).then(res => {
					this.$set(this, 'qrCodeInfo', res);
					this.$store.commit('set_productId', res.proId);
					this.userId = res.userId;

					// 修改title
					// this.$base.setTitle(res.remark)
					resolve(res);
				});
			});
		},

		// 获取商品信息
		getGoods() {
			productDetail(this.qrCodeInfo.proId).then(res => {
				this.proBanner = res.product.proPicUrlIndexBig;
				this.insureInfo = res.insurer;
				this.proSimpleInfo = res.product;
				this.information = res.information;
				this.questionList = res.questionList;
				// this.isV1 = res.product.versions != 1;
				// this.isLiability = res.product.insuranceCode === 2;
				// if (!this.isV1) {
				// 	this.getV2Data();
				// }
			});
		},

		// 获取商品的所有计划
		getPlain() {
			let send = {
				comboId: '',
				backInsureFlag: 1,
			};
			getInsuerInfo(this.qrCodeInfo.proId, send).then(res => {
				this.proInfo = res.product;
				this.proPlanList = res.combos;

				this.immediateExtendTime = res.product.immediateExtendTime;
				// 取保险公司名称
				this.insurerFullName = res.insurer.insurerFullName;
				// 取告知书文档链接
				if (res.bookList) {
					let bookList = res.bookList.filter(item => item.docType === '03');
					if (bookList.length) {
						this.notificationLink = res.productFileServer + bookList[0].docLink;
					}
				}
			});
		},

		// 计划改变
		planChange(plan) {
			this.planId = plan.comboId;
		},

		// 保障期限改变
		insureTimeChange(info) {
			this.protectInfo = info;
			this.getPrice(info);
		},

		// 计算价格
		getPrice(insureInfo) {
			let send = {
				comboId: this.planId,
				eleView1: '',
				eleView2: '',
				insureTime: insureInfo.insure_time,
				insureTimeUnit: insureInfo.insure_time_unit,
				priceId: insureInfo.price_id,
				proId: this.qrCodeInfo.proId,
				sex: '',
				viewAgeBelong: '',
				viewTime: insureInfo.view_time,
			};
			getPrice(send).then(res => {
				this.price = Number(res.priceInfo.price);
				this.protectViewTime = res.priceInfo.viewTime;
			});
		},

		// 购买
		buy() {
			// 条款勾选 clauseInfo
			let clauseInfoData = this.$refs.clauseInfo[0].getResult();
			if (!clauseInfoData) return;
			// if (!this.isV1) {
			// 	this.buyV2();
			// 	return;
			// }

			// 投保信息 insureInfo
			this.$refs.insureInfo[0].getResult().then(insureInfoData => {
				// 投保人信息 insureUser
				this.$refs.insureUser[0].getResult().then(insureUserData => {
					// 被保人信息 assured
					this.$refs.assured[0].getResult().then(assuredData => {
						// 即时起保，从服务器获取时间戳,防止客户端时间不准
						if (insureInfoData.isNextSecondStart) {
							Toast.loading({
								duration: 0, // 持续展示 toast
								forbidClick: true,
								message: '提交订单中',
							});
							http_getServerTime().then(res => {
								Toast.clear();
								this.submitForm(insureInfoData, insureUserData, assuredData, Number(res.timestamp)).then(order => {
									this.mixin_updateSignaturesBillId(order.id);
									// 前往支付页面
									this.$store.commit('set_billId', order.id);
									khs.finish(order.id);

									this.$router.push({
										name: 'fixedCodeOrderPay',
										params: {
											isRedict: false,
										},
									});
								});
							});
						} else {
							this.submitForm(insureInfoData, insureUserData, assuredData).then(order => {
								this.mixin_updateSignaturesBillId(order.id);
								// 前往支付页面

								this.$store.commit('set_billId', order.id);
								khs.finish(order.id);
								this.$router.push({
									name: 'fixedCodeOrderPay',
									params: {
										isRedict: false,
									},
								});
							});
						}
					});
				});
			});
		},

		sexFormatter(text) {
			if (text === '男' || text === 'male') {
				return '男';
			}

			if (text === '女' || text === 'female') {
				return '女';
			}
			return text;
		},

		// 提交订单 投保信息  投保人信息  被保险人 时间戳
		submitForm(insureInfo, insureUser, assured, timeStamp = 0) {
			let assuredList = assured.map(item => {
				return {
					name: item.userName,
					certificateType: item.cardType,
					certificateContent: item.cardNum,
					birthday: this.$base.submitDateFormatter(item.birthday),
					sex: this.sexFormatter(item.sex),
					mobile: item.phone,
				};
			});

			let send = {
				assuredList, // 被保人
				insurer: {
					insBirthday: this.$base.submitDateFormatter(insureUser.birthday),
					insCredentials: insureUser.cardNum,
					insCredentialsType: insureUser.finalCardType,
					insEmail: insureUser.email,
					insName: insureUser.userName,
					insPhone: insureUser.phone,
					insSex: insureUser.sex,
					insType: insureUser.insType,
				},
				extentionList: [],
				insBill: {
					enableDate: `${this.$base.submitDateFormatter(insureInfo.start)} ${insureInfo.isNextSecondStart ? this.$base.getTime(timeStamp + this.immediateExtendTime * 1000 * 60) : insureInfo.startSecond}`,
					disEnableDate: this.$base.submitDateFormatter(insureInfo.end, 2), //终保日期
					id: '',
					insureType: '2', //出单方式
					proId: this.qrCodeInfo.proId, //产品ID
					remark: '',
					platform: 'LP',
					assuredSendMsg: 1,
					lpId: this.qrCodeInfo.id,
				},
				optType: '2',
				productPriceVo: {
					comboId: this.planId,
					proId: this.qrCodeInfo.proId,
					viewTime: this.protectInfo.view_time,
					eleView1: '',
					eleView2: '',
					priceId: this.protectInfo.price_id,
					sex: '',
					viewAgeBelong: '',
					insureTime: this.protectInfo.insure_time,
					insureTimeUnit: this.protectInfo.insure_time_unit,
				},
			};
			Toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				message: '提交订单中',
			});
			return new Promise(resolve => {
				submitBill(send).then(
					res => {
						Toast.clear();
						Toast.success('订单提交成功');
						resolve(res.data);
					},
					err => {
						console.log(err);
					},
				);
			});
		},
		// 获取v2数据
		async getV2Data() {
			Toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				message: '加载中',
			});
			this.$store.dispatch('getCodeCategory');
			this.$store.dispatch('getCode');
			this.$store.dispatch('getCountry');
			//获取职业信息
			await this.$store.dispatch('getCareer');

			this.getProductV2();
		},
		// 获取v2的商品信息
		async getProductV2() {
			const res = await http_getProductConfig({ proId: this.qrCodeInfo.proId });
			// 获取省市区
			await this.$store.dispatch('getArea', res.insurer2Vo.insurerCode);
			this.productDataV2 = res.insFormGroup2VoList.filter(group => group.groupCode === 'product')[0];
			const insureDataV2 = res.insFormGroup2VoList.filter(group => group.groupCode === 'insure')[0];
			if (this.qrCodeInfo.fixedInsureInfo) {
				this.setFixLxr(insureDataV2);
			} else {
				this.insureDataV2 = insureDataV2;
			}
			this.assuredDataV2 = res.insFormGroup2VoList.filter(group => group.groupCode === 'assured')[0];
			this.comboList = res.comboList;
			this.productInfo = res.insProduct2Vo;
			this.setFieldParent(res.insFormGroup2VoList);
			Toast.clear();
		},

		// 联系人
		setFixLxr(groupList) {
			let data = {
				insName: this.qrCodeInfo.insureName,
				insType: this.qrCodeInfo.insureType === '个人' ? '1' : '2',
				insCredentialsType: this.qrCodeInfo.cardType,
				insCredentials: this.qrCodeInfo.cardNum,
				insSex: this.qrCodeInfo.sex,
				insBirthday: this.qrCodeInfo.birthday,
				insPhone: this.qrCodeInfo.mobile,
				insEmail: this.qrCodeInfo.email,
			};
			const groupData = groupList?.insForm2VoList;

			//有重复的字段名需要判断父级是个人还是单位团体
			for (let key in data) {
				for (let v of groupData) {
					if (key === v.fieldName) {
						if (data[key]) {
							const parentValue = JSON.parse(v.parentValue);
							let parentField = '';
							if (v?.parentId) {
								parentField = groupData.filter(item => item.id === v?.parentId)[0]?.fieldName;
							}
							if (parentValue.length > 0 && parentField && data[parentField] && parentValue.includes(data[parentField]?.toString())) {
								v.defaultValue = JSON.stringify(String(data[key]));
							}
							if (parentValue.length === 0) {
								v.defaultValue = JSON.stringify(String(data[key]));
							}
						}
					}
				}
			}
			this.insureDataV2 = groupList;
		},
		//同意进入投保流程
		agreeEnter() {
			this.enterWarning = false;
			khs.start(this.userId);
		},
		async getFormData() {
			let product = await this.$refs.productForm[0].getData();
			// 投保人信息
			let insure = await this.$refs.insureForm[0].getData();
			// 被保人信息
			let assured = await this.$refs.assuredForm[0].getData();
			return Promise.resolve({ product, insure, assured });
		},
		// 收集所有字段的父级字段(由于字段的上级报文字段只在与提交订单接口或回显接口交互的时候使用，所以在此处统一处理)
		setFieldParent(list) {
			let result = {};
			list.forEach(group => {
				group?.insForm2VoList.forEach(field => {
					if (field.parentCode) {
						result[field.fieldName] = field.parentCode;
					}
				});
			});
			this.fieldParent = result;
		},
		// v2购买
		async buyV2() {
			// 获取购买信息
			const { product, insure, assured } = await this.getFormData();

			if (!this.isLiability && assured.assured.length === 0) {
				Toast('请填写被保人信息');
				return;
			}

			let send = {};
			// 产品信息
			send.insuranceProductList = [{ proId: product.product.proId, comboId: product.product.comboId }];
			// 订单信息
			send.insuranceBill = {
				billId: '',
				enableDate: product.product.start,
				disEnableDate: product.product.end,
				premium: Number(this.totalPriceV2),
				assuredSendMsg: 0,
				platform: 'LP',
			};
			Object.assign(send.insuranceBill, product.insurance);

			// 投保人信息
			send.insuranceApplicant = {};
			Object.assign(send.insuranceApplicant, insure);
			// 被保人信息
			if (this.isLiability) {
				// 责任险
				send.insuranceInsuredList = [
					{
						...assured,
						insuranceInsuredPriceList: [
							{
								proId: product.product.proId,
								comboId: product.product.comboId,
								priceId: product.product.priceId,
							},
						],
					},
				];
			} else {
				send.insuranceInsuredList = assured.assured.map(item => {
					return {
						name: item.name,
						certificateType: item.certificateType,
						certificateContent: item.number,
						birthday: item.birthday,
						sex: item.sex,
						mobile: item.phone,
						englishName: item.englishName,
						profession: item?.profession,
						insuranceInsuredPriceList: [
							{
								proId: product.product.proId,
								comboId: product.product.comboId,
								priceId: product.product.priceId,
							},
						],
					};
				});
			}

			// 扩展字段
			send.extentionVoList = [];

			// 从订单信息、投保人信息中删除有父级报文字段的字段
			let parentFieldList = Object.keys(this.fieldParent);
			let parentFeildValue = {};
			for (const key in send.insuranceBill) {
				if (Object.hasOwnProperty.call(send.insuranceBill, key)) {
					const val = send.insuranceBill[key];
					if (parentFieldList.includes(key)) {
						parentFeildValue[key] = val;
						delete send.insuranceBill[key];
					}
				}
			}
			for (const key in send.insuranceApplicant) {
				if (Object.hasOwnProperty.call(send.insuranceApplicant, key)) {
					const val = send.insuranceApplicant[key];
					if (parentFieldList.includes(key)) {
						parentFeildValue[key] = val;
						delete send.insuranceApplicant[key];
					}
				}
			}
			// 把有父级报文字段的数据再对号入座
			for (const key in parentFeildValue) {
				if (Object.hasOwnProperty.call(parentFeildValue, key)) {
					const val = parentFeildValue[key];
					if (this.fieldParent[key] === 'extentionVoList') {
						let str = val;
						if (Array.isArray(val)) {
							str = val.join(',');
						} else if (typeof str !== 'string') {
							str = JSON.stringify(val);
						}
						// 父级报文字段是扩展字段时
						send.extentionVoList.push({
							colName: key,
							colValue: str,
						});
					} else {
						if (!send[this.fieldParent[key]]) {
							send[this.fieldParent[key]] = {};
						}
						send[this.fieldParent[key]][key] = val;
					}
				}
			}
			Toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				message: '提交订单中',
			});
			http_insuredOrder(send)
				.then(res => {
					if (res.success) {
						if (res.data.isSplit) {
							this.$store.commit('set_billId', res.data.billIdList.join(','));
							this.$router.push({
								name: 'fixedCodeOrderPay',
								params: {
									isRedict: false,
								},
							});
						} else {
							// 不拆单
							let orderId = res.data.mainBill.id;
							this.$store.commit('set_billId', orderId);

							this.$router.push({
								name: 'fixedCodeOrderPay',
								params: {
									isRedict: false,
								},
							});
						}
					} else {
						if (res.code == '4400' && res.data) {
							const message = res.data[0]?.name + ':' + res.data[0]?.errorDesc;
							Dialog.confirm({
								title: '错误提示',
								message: message,
								showCancelButton: false,
								confirmButtonColor: '#2594ef',
							}).then(() => {});
						} else {
							Toast.fail(res.message);
						}
					}
				})
				.finally(() => {
					Toast.clear();
				});
		},
	},
};
</script>
